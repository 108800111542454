import styled from "styled-components"

export const DropDownLabelAndErrorContainer = styled.div`
display: flex;
align-items: center;
justify-content: space-between;
`
export const TextLabel = styled.label`
`
export const TextLabelSpan = styled.span`
color: red;
`